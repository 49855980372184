.blog-post-header55-blog-post-header3 {
  display: flex;
  position: relative;
  align-items: center;
}
.blog-post-header55-max-width {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 100%;
  display: flex;
}
.blog-post-header55-section-title {
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.blog-post-header55-title1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.blog-post-header55-breadcrumbs {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
}
.blog-post-header55-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blog-post-header55-content2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blog-post-header55-author {
  gap: var(--dl-space-space-halfunit);
  display: flex;
}
.blog-post-header55-time {
  gap: 8px;
  display: flex;
  align-items: center;
}
.blog-post-header55-container {
  flex: 1;
  width: 423px;
  height: 560px;
  display: flex;
  max-width: 560px;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.blog-post-header55-image1 {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.blog-post-header55-text14 {
  display: inline-block;
}
.blog-post-header55-text15 {
  display: inline-block;
}
.blog-post-header55-text16 {
  display: inline-block;
}
.blog-post-header55-text17 {
  display: inline-block;
}
.blog-post-header55-text18 {
  display: inline-block;
}
.blog-post-header55-text19 {
  display: inline-block;
}
@media(max-width: 767px) {
  .blog-post-header55-max-width {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .blog-post-header55-container {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .blog-post-header55-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .blog-post-header55-title1 {
    align-items: center;
  }
  .blog-post-header55-content1 {
    align-items: center;
  }
  .blog-post-header55-content2 {
    align-items: center;
  }
  .blog-post-header55-text15 {
    text-align: center;
  }
  .blog-post-header55-text19 {
    text-align: center;
  }
}
