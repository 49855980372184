.contact10-container1 {
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.contact10-max-width {
  align-self: center;
}
.contact10-content1 {
  width: 100%;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.contact10-content2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.contact10-content3 {
  width: 100%;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.contact10-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact10-image1 {
  object-fit: cover;
}
.contact10-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.contact10-container4 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.contact10-image2 {
  object-fit: cover;
}
.contact10-container5 {
  flex: 0 0 auto;
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.contact10-text17 {
  display: inline-block;
}
.contact10-text18 {
  display: inline-block;
}
.contact10-text19 {
  display: inline-block;
  text-align: center;
}
.contact10-text20 {
  display: inline-block;
}
.contact10-text21 {
  display: inline-block;
  text-align: center;
}
.contact10-text22 {
  display: inline-block;
}
@media(max-width: 991px) {
  .contact10-content1 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  .contact10-content3 {
    position: relative;
    align-items: center;
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .contact10-content1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .contact10-image1 {
    width: 100%;
  }
  .contact10-image2 {
    width: 100%;
  }
}
