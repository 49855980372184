.blog-post-header5-blog-post-header3 {
  display: flex;
  position: relative;
  align-items: center;
}
.blog-post-header5-max-width {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  height: 100%;
  display: flex;
}
.blog-post-header5-section-title {
  flex: 1;
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.blog-post-header5-title1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.blog-post-header5-breadcrumbs {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
}
.blog-post-header5-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blog-post-header5-content2 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.blog-post-header5-author {
  gap: var(--dl-space-space-halfunit);
  display: flex;
}
.blog-post-header5-time {
  gap: 8px;
  display: flex;
  align-items: center;
}
.blog-post-header5-container {
  flex: 1;
  width: 423px;
  height: 560px;
  display: flex;
  max-width: 560px;
  align-self: center;
  align-items: center;
  justify-content: center;
}
.blog-post-header5-image1 {
  flex: 1;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}
.blog-post-header5-text4 {
  display: inline-block;
}
.blog-post-header5-text5 {
  display: inline-block;
}
.blog-post-header5-text6 {
  display: inline-block;
}
.blog-post-header5-text7 {
  display: inline-block;
}
.blog-post-header5-text8 {
  display: inline-block;
}
@media(max-width: 767px) {
  .blog-post-header5-max-width {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .blog-post-header5-container {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .blog-post-header5-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .blog-post-header5-title1 {
    align-items: center;
  }
  .blog-post-header5-content1 {
    align-items: center;
  }
  .blog-post-header5-content2 {
    align-items: center;
  }
  .blog-post-header5-text5 {
    text-align: center;
  }
  .blog-post-header5-text7 {
    text-align: center;
  }
}
