.content-list1-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.content-list1-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.content-list1-text11 {
  display: inline-block;
}
.content-list1-text12 {
  display: inline-block;
}
.content-list1-text13 {
  display: inline-block;
}
.content-list1-text14 {
  display: inline-block;
}
.content-list1-text15 {
  display: inline-block;
}
.content-list1-text16 {
  display: inline-block;
}
.content-list1-text17 {
  display: inline-block;
}
.content-list1-text18 {
  display: inline-block;
}
.content-list1-text19 {
  display: inline-block;
}
.content-list1-text20 {
  display: inline-block;
}
.content-list1-text21 {
  display: inline-block;
}
.content-list1-text22 {
  display: inline-block;
}
.content-list1-text23 {
  display: inline-block;
}
.content-list1-text24 {
  display: inline-block;
}
.content-list1-text25 {
  display: inline-block;
}
.content-list1-text26 {
  display: inline-block;
}
.content-list1-text27 {
  display: inline-block;
}
.content-list1-text28 {
  display: inline-block;
}
@media(max-width: 991px) {
  .content-list1-container {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .content-list1-text14 {
    text-align: center;
  }
  .content-list1-text16 {
    text-align: center;
  }
  .content-list1-text20 {
    text-align: center;
  }
  .content-list1-text22 {
    text-align: center;
  }
  .content-list1-text25 {
    text-align: center;
  }
  .content-list1-text27 {
    text-align: center;
  }
}
